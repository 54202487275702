<template>
  <div>
    <pro-document-breadcrumbs
      :breadcrumbs="[{ name: 'menu.ModuleSetting', icon: 'PROSmart-Setting' }]"
      user-role="procurer"
    ></pro-document-breadcrumbs>

    <q-card style="margin-top: 10px">
      <!--    <h3 style="margin: 1rem 0 0 1rem">-->
      <!--      {{ getRes("Form.Option.Search") }}{{ getRes("menu.ModuleSetting") }}-->
      <!--    </h3>-->
      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab name="tender" label="Tender" />
        <q-tab name="rfq" label="RFQ" />
        <q-tab name="eoi" label="EOI" />
        <q-tab name="pq" label="PQ" />
        <q-tab name="rfi" label="RFI" />
      </q-tabs>
      <q-tab-panels v-model="tab" animated>
        <q-tab-panel
          class="scroll"
          v-for="items in tabs"
          :key="items"
          :name="`${items}`"
        >
          <q-card
            flat
            bordered
            v-for="section in data"
            :key="section.name"
            style="margin-bottom: 10px"
          >
            <q-card-section style="padding-left: 0; padding-right: 0">
              <div
                class="text-h4"
                v-text="section.name"
                style="padding: 0 16px 16px 16px"
              />

              <q-markup-table flat>
                <tbody>
                  <tr v-for="item in section.value" :key="item">
                    <td class="text-left" style="width: 80%">
                      <div
                        class="text-h5"
                        style="font-weight: bolder"
                        v-text="item.keyName"
                      />
                      <div class="text-h6" v-html="item.keyDescription" />
                    </td>
                    <td style="width: 20%">
                      <q-input
                        v-if="!item.keyType || item.keyType === 'string'"
                        :dense="true"
                        class="col-2"
                        outlined
                        v-model="item.keyValue"
                      />
                      <q-select
                        v-if="
                          item.keyType &&
                          item.keyType.indexOf('|') !== -1 &&
                          item.keyType.indexOf('||') === -1
                        "
                        outlined
                        class="col-2"
                        :dense="true"
                        v-model="item.keyValue"
                        :options="typeSplit(item.keyType)"
                      />
                      <template
                        v-if="item.keyType && item.keyType.indexOf('||') !== -1"
                      >
                        <q-option-group
                          class="col-2"
                          :options="optionGroup(item.keyType)"
                          type="checkbox"
                          v-model="item.keyValue"
                        />
                      </template>
                      <el-input-number
                        v-if="item.keyType && item.keyType === 'number'"
                        class="col-2"
                        v-model="item.keyValue"
                        @change="handleChange"
                      />
                      <q-toggle
                        dense
                        v-if="item.keyType && item.keyType === 'boolean'"
                        style="padding-bottom: 16px"
                        v-model="item.keyValue"
                      />

                      <pro-json-setting
                        v-if="item.keyType && item.keyType === 'json'"
                        v-model="item.keyValue"
                      />
                    </td>
                  </tr>
                </tbody>
              </q-markup-table>
            </q-card-section>
          </q-card>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <div class="row justify-end">
      <div class="col-auto">
        <q-btn
          @click="Save"
          style="margin-top: 16px; margin-bottom: 16px"
          color="primary"
          :label="getRes('System.Button.Save')"
          no-caps
        ></q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProJsonSetting from "@/components/PROSmart/Setting/ProJsonSetting.vue";

export default {
  components: { ProJsonSetting, ProDocumentBreadcrumbs },
  data() {
    return {
      data: [],
      tabs: ["tender", "rfq", "eoi", "pq", "rfi"],
      tab: "tender",
    };
  },
  methods: {
    typeSplit(type) {
      return type.split("|");
    },
    typeSplits(type) {
      return type.split("||");
    },
    optionGroup(arrayString) {
      return arrayString.split("||").map((b) => {
        return { label: b, value: b };
      });
    },
    arrayGroupBy(array, groupParam) {
      const groupedArr = array.reduce((pre, item) => {
        const groupName = item[groupParam];
        pre[groupName] = [...(pre[groupName] || []), item];
        return pre;
      }, {});
      this.data = Object.keys(groupedArr).map((key) => {
        return {
          name: key,
          value: groupedArr[key],
        };
      });
    },
    getSetting(ModuleName) {
      this.$proSmart.admin.GetModuleSetting(this, ModuleName).then((rs) => {
        this.data = rs;
        for (let i in this.data) {
          console.log(this.data[i].keyType);
          if (this.data[i].keyValue === "True") {
            this.data[i].keyValue = true;
          }
          if (this.data[i].keyValue === "False") {
            this.data[i].keyValue = false;
          }
          if (
            this.data[i].keyType &&
            this.data[i].keyType.indexOf("||") !== -1
          ) {
            this.data[i].keyValue = this.data[i].keyValue.split(",");
            console.log("test", this.data[i].keyValue);
          }
        }
        this.arrayGroupBy(this.data, "sectionName");
        console.log(this.data);
      });
    },
    Save() {
      const mirror_data = JSON.parse(JSON.stringify(this.data));
      const ready_data = [];
      mirror_data.forEach((items) => {
        items.value.forEach((item) => {
          ready_data.push(item);
        });
      });

      for (let i in ready_data) {
        for (let a in ready_data[i]) {
          if (ready_data[i][a] === null) {
            ready_data[i][a] += "";
          }
        }
        if (typeof ready_data[i].keyValue === "number") {
          ready_data[i].keyValue = ready_data[i].keyValue.toString();
        }
        if (typeof ready_data[i].keyValue === "boolean") {
          ready_data[i].keyValue = ready_data[i].keyValue.toString();
          const a = ready_data[i].keyValue.charAt(0).toUpperCase();
          ready_data[i].keyValue = a + ready_data[i].keyValue.slice(1);
        }
        if (
          ready_data[i].keyType &&
          ready_data[i].keyType.indexOf("||") !== -1
        ) {
          ready_data[i].keyValue = ready_data[i].keyValue.join(",");
          console.log("test", ready_data[i].keyValue);
        }
      }
      const moduleInfo = {
        moduleList: ready_data,
      };
      console.log(moduleInfo);
      this.$proSmart.admin
        .UpdateModuleSettingList(this, this.tab, JSON.stringify(moduleInfo))
        .then(() => {
          this.$alert(
            this.$t("WorkflowOperation.OperationSucceed"),
            this.$t("notification"),
            { confirmButtonText: this.getRes("ok") }
          );
        });
    },
  },
  watch: {
    tab: {
      handler(New) {
        this.getSetting(New);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ {
  .high-light {
    color: #04b21e;
    font-weight: bolder;
  }
}
</style>
